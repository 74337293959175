<template>
  <div>
    <h3 ref="intro">
      Boyle's Law
    </h3>
    <p>
      Boyle's law is an empirical law (i.e. a law derived based on experimential observations) that relates the volume occupied by a gas to the pressure exerted on it.
      Boyle's law states that the volume occupied by a fixed mass of gas changes inversely with the pressure exerted on it provided that the temperature of the gas is kept fixed.
      Mathematically, Boyle's law can be expressed as
      $$ V \propto \frac{1}{P}$$
      where \(V\) is the volume of a given mass of the gas and \(P\) is the pressure exerted on the gas.<br>
      By introducing a proportionality factor &kappa;, Boyle's law can be expressed as
      $$ V = \frac{\kappa}{P}$$
      Note that the proportionality factor &kappa; remains constant as long as temperature is kept fixed. It will change if the temperature is changed.
      Thus, Boyle's law states that, at a fixed temperature, the product of the pressure and volume of gas is a constant.
      $$PV = \kappa$$
    </p>
    <h3 ref="iso">
      Isothermal Processes
    </h3>
    <p>
      Boyle's law can be used to relate the volumes and pressures of a gas during an isothermal process.
      An isothermal process is defined as a process in which the temperature is kept constant.
      Let's say a given mass of the gas occupies a volume \(V_1\) at pressure \(P_1\) at the beginning of the process.
      Let's say the same mass of gas occupies a volume \(V_2\) and is at pressure \(P_2\) at the end of the process.
      Then, Boyle's law can be used to relate pressures and volumes of the gas during the process as follows:
      $$P_1 V_1 = P_2 V_2$$
      <!--<div id="imgs">
<img src="/assets/picture.png" width=300px>
</div> -->
    </p>
    <h3 ref="pg">
      MagicGraph: Pressure & Volume in an Isothermal Process
    </h3>
    <p>
      Through this visually interactive MagicGraph, students will learn how the volume of a gas contained in a closed vessel changes with its pressure during
      an isothermal process using Boyle's law.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a cylindrical vessel containing a gas. The cross-section area of the vessel is 1 \(m^2\). The temperature of the
      gas in the cylinder is kept constant. The pressure of the gas can be increased (or decreased) by
      adding (or removing) weights.
      <br>
      Tap on the + button to add weights and - button to remove.
      As you add or remove weights, observe how the volume changes with pressure.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ElectronicStructure',
  components: {
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
    }
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Boyle\'s Law';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Boyle\'s Law',img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Isothermal Processes', img:'/assets/number-2.svg', action: () => this.goto('iso')},
      {title: 'MagicGraph', img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newChem =true;
    this.$store.commit('navigation/replaceChem', newChem);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
