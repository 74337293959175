const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var board1 = JXG.JSXGraph.initBoard('jxgbox1', {boundingbox: [-1, 13, 16, -4],
  keepaspectratio: true, axis:true, grid:true, ticks:false, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
//Platform
board1.options.layer['image'] =15;
board1.options.layer['polygon'] =14;
var resize = function () {
    board1.resizeContainer(board1.containerObj.clientWidth, board1.containerObj.clientHeight, true);
    board1.fullUpdate();
    };
window.addEventListener("resize", resize);
//board1.offsetX = 2;
//board1.moveOrigin(0,0, true);
board1.suspendUpdate();
//Texts
var a=8; var b=3;
board1.create('text', [7.5, 12, '<b> Boyle\'s Law ( V &propto; 1/P ) <b>'],{highlight:false, anchorX:'middle', anchorY:'bottom', fixed:true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(32*board1.canvasWidth/800)}});
board1.create('text', [7.5, 11, '(Change Pressure by Adding or Removing Weights)'],{highlight:false, anchorX:'middle', anchorY:'bottom', fixed:true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*board1.canvasWidth/800)}});
var platform=board1.create('polygon',[[-9+a,-10+b],[20+a,-10+b],[20+a,-3+b],[-9+a, -3.+b]], {fillColor:'grey', withLines:false, vertices:{visible:false}});
//flask
var flask=board1.create('polygon',[[a+5,b-4.],[a+6,b-4.],[a+6,b+5],[a+5, b+5]], {fillColor:'black', fillOpacity:1, withLines:false, vertices:{visible:false}});
board1.create('polygon',[[a,b-4.],[a+1,b-4.],[a+1,b+5],[a, b+5]], {fillColor:'black', fillOpacity:1, withLines:false, vertices:{visible:false}});
board1.create('polygon',[[a,b-4.],[a+6,b-4.],[a+6,b-3],[a, b-3]], {fillColor:'black', fillOpacity:1, withLines:false, vertices:{visible:false}});

//Interior
//var interior=board1.create('polygon',[[a+1,b-3.],[a+5,b-3.],[a+5, b+5.01],[a+1, b+5.01]], {fillColor:'oldLace', withLines:false, fillOpacity:1, vertices:{visible:false}, borders:{visible:false}});
//line
var line = board1.create('line', [[a+3,b], [a+3, b+10]], {visible: false, straightFirst: false, straightLast: false});
//
var Q=0;
var i=1;
var m =5;
var g = 9.8;
var R = 8.315;
var Cv = function(){return (2+3)*R.valueOf()/2};
var To = 25;
var delt = function(){return Q.valueOf()/(Cv()+R);}
//Pressure
var P = m*9.8/1.0;
//height
var delh = function(){return R*delt/P};
//Temp_inside
//Temp_outside
board1.create('text', [3+a, b+7.0, 'T = Constant'], {highlight:false, anchorX:'middle',fixed:true, fontSize:function(){return 28*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//block
var up =board1.create('image', ['/assets/plus.svg', [a+2.5-0.75, b-5.75], [1.35,1.35]],{fixed:true});
up.setLabel('Tap to add weight')
up.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*board1.canvasHeight/800}});
up.on('over', function () {this.label.setAttribute({visible:true});});
up.on('out', function () {this.label.setAttribute({visible:false});});
var down =board1.create('image', ['/assets/minuss.svg', [a+2.5+0.75, b-5.75], [1.35,1.35]],{fixed:true});
down.setLabel('Tap to remove weight')
down.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*board1.canvasHeight/800}});
down.on('over', function () {this.label.setAttribute({visible:true});});
down.on('out', function () {this.label.setAttribute({visible:false});});
//var pause = board1.create('image', ['pause.svg', [-2, -6], [1.5,1.5]]);
var mass = board1.create('point', [a+3, b+3], {name: '', size:0, face:'square', label:{offset:[-20, 0]}});
var lin =board1.create('segment', [[a+1, function(){return mass.Y();}],[a-8,function(){return mass.Y();}]],{strokeWidth:1, dash:1, strokeColor:'black'});
//tape
var wt=[];
wt[0] =board1.create('image', ['/assets/weight.svg', [1.1+a, function(){return mass.Y();}], [0.75, 0.75]], {highlight:false, visible:function(){if(i>=1){return true}else{return false}}});
wt[1] =board1.create('image', ['/assets/weight.svg', [1.85+a, function(){return mass.Y();}], [0.75, 0.75]],{highlight:false,visible:function(){if(i>=2){return true}else{return false}}});
wt[2] =board1.create('image', ['/assets/weight.svg', [2.6+a, function(){return mass.Y();}], [0.75, 0.75]],{highlight:false,visible:function(){if(i>=3){return true}else{return false}}});
wt[3] =board1.create('image', ['/assets/weight.svg', [3.35+a, function(){return mass.Y();}], [0.75, 0.75]],{highlight:false,visible:function(){if(i>=4){return true}else{return false}}});
wt[4] =board1.create('image', ['/assets/weight.svg', [4.1+a, function(){return mass.Y();}], [0.75, 0.75]],{highlight:false,visible:function(){if(i>=5){return true}else{return false}}});
up.on('down', function(){if(i<5){i+=1; mass.moveTo([3+a, 6/(1+Math.max(i,0))],1000)}else{return;}});
down.on('down', function(){if(i>0){i-=1;mass.moveTo([3+a, 6/(1+Math.max(i,0))],1000)}else{return;}});
//
board1.create('text', [3+a, ()=>mass.Y()*0.5, ()=>'P = '+ (i+1).valueOf()+' kgf/m^2'], {anchorX:'middle',highlight:false,fontSize:function(){return 18*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
board1.create('image', ['/assets/weight.svg', [2, -3], [0.75, 0.75]], {highlight:false});
board1.create('text', [3, -2.675, '= 1 kgf'], {highlight:false,fixed:true, fontSize:function(){return 28*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//
var Pts=[];
board1.create('point', [1, 6/1], {name:'P_1, V_1', fixed:true, label:{anchorX:'middle', offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black', visible:function(){if(i>=0){return true}else{return false}}})
Pts[0] =board1.create('point', [2, 6/2], {name:'P_2, V_2', fixed:true, label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black', visible:function(){if(i>=1){return true}else{return false}}});
Pts[1] =board1.create('point', [3, 6/3], {name:'P_3, V_3', fixed:true, label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black',visible:function(){if(i>=2){return true}else{return false}}});
Pts[2] =board1.create('point', [4, 6/4], {name:'P_4, V_4', fixed:true, label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'},strokeColor:'black',visible:function(){if(i>=3){return true}else{return false}}});
Pts[3] =board1.create('point', [5, 6/5], {name:'P_5, V_5', fixed:true,label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'},strokeColor:'black',visible:function(){if(i>=4){return true}else{return false}}});
Pts[4] =board1.create('point', [6, 6/6], {name:'P_6, V_6', fixed:true,label:{anchorX:'middle', offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black',visible:function(){if(i>=5){return true}else{return false}}});
var v =0; var i=0; var j=0;
//wt1.on('down', function(){if(i<=4 && j<1){wt1.moveTo([1+i, mass.Y()], 1000); v=+1; i+=1; j+=1;}});
//wt2.on('down', function(){if(i<=4){wt2.moveTo([1+0.75*i,mass.Y()], 1000); v+=1; i+=1;}});
//wt3.on('down', function(){if(i<=4){wt3.moveTo([1+0.75*i,mass.Y()], 1000); v+=1; i+=1;}});
//wt4.on('down', function(){if(i<=4){wt4.moveTo([1+0.75*i,mass.Y()], 1000); v+=1; i+=1;}});
//wt5.on('down', function(){if(i<=4){wt5.moveTo([1+0.75*i,mass.Y()], 1000);v+=1;  i+=1;}});
//pause.on('down', function(){i=0; Q=0;});
board1.create('tapemeasure', [[6.5+a, -3+b], [6.5+a, function(){return mass.Y()}]], {name:'V(m^3)',
 withLabel: true, label:{fontSize:function(){return 20*board1.canvasHeight/800},cssStyle:'fontFamily:Oswald;', highlight:false},
 fixed:true, point1:{size:3,face:'square',strokeColor:'red', fillColor:'red',fixed:true},
point2:{size:3,face:'square', color:'red', fixed:true}});
//board1.create('tapemeasure', [[6.5+a, -3+b], [6.5+a, 3.0+b]], {name:'h_i', withLabel: true, ticks:false,label:{fontSize:16,cssStyle:'fontFamily:Oswald;'}, fixed:true, point1:{size:4,face:'<',color:'black'}, point2:{size:4,face:'<'}});
//Piston
//gas
var gas=board1.create('polygon',[[1+a,b-3.],[a+5,b-3.],[a+5,function(){return mass.Y();}],[1+a, function(){return mass.Y();}]], {fillColor:'lightblue', withLines:false, fillOpacity:1., vertices:{visible:false}});
//
var piston = board1.create('polygon',[[1+a, function(){return mass.Y();}],[5+a,function(){return mass.Y();}],[5+a,function(){return mass.Y()-0.1;}],[1+a, function(){return mass.Y()-0.1;}]], {fillColor:'black', withLines:false, fillOpacity:1, vertices:{visible:false}});
//
board1.create('functiongraph',[function(x){return 6/x}, 1, function(){return 1+i}],{strokeWidth:2, strokeColor:'black'});
//
board1.create('text', [0.1, 12, 'V(m^3)'], {highlight:false, fontSize:function(){return 24*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
board1.create('text', [4, -1., 'P(kgf/m^2)'], {highlight:false, fontSize:function(){return 24*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
board1.unsuspendUpdate();},
box2:function(){
  var board2 = JXG.JSXGraph.initBoard('jxgbox2', {boundingbox: [-7, 10, 10, -5], keepaspectratio: true, axis:false, grid:false, ticks:false, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //Platform
  board2.options.layer['image'] =14;
  board2.suspendUpdate();
  board2.create('text', [5, 9., '<b> Diatomic Gas <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*board2.canvasWidth/500.)}});
  var platform=board2.create('polygon',[[-9,-10],[20,-10],[20,-4],[-9, -4.]], {fillColor:'grey', withLines:false, vertices:{visible:false}});
  var i=0;
  //flask
  var flask=board2.create('polygon',[[0,-4.],[6,-4.],[6,5],[0, 5]], {fillColor:'black', fillOpacity:1, withLines:false, vertices:{visible:false}});
  //Interior
  var interior=board2.create('polygon',[[1,-3.],[5,-3.],[5,5.01],[1, 5.01]], {fillColor:'white', withLines:false, fillOpacity:1, vertices:{visible:false}, borders:{visible:false}});
  //line
  var line = board2.create('line', [[3,0], [3, 10]], {visible: false, straightFirst: false, straightLast: false});
  //thermometer
  //var thermo = board2.create('slider',[[8.,-4.],[8., 4.],[-4500, 0, 4500]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'Q(J)',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false,label:{offset:[2,-15], fontSize:20, parsing:false, useMathJax:true}});
  //
  var Q=0;
  var m =5;
  var g = 9.8;
  var R = 8.315;
  var Cv = 7*R/2;
  var To = 25;
  var delt = function(){return Q.valueOf()/(Cv+R);}
  //Pressure
  var P = m*9.8/1.0;
  //height
  var delh = function(){return R*delt/P};
  //Temp_inside
  var tempVal = board2.create('text', [2.0, -2., function(){return 'T_{In} =' + (To+ Q.valueOf()/(Cv+R)).toFixed(2) + '^oC'}], {fontSize:function(){return 22*board2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  //Temp_outside
  var tempVal = board2.create('text', [7., 0.0, function(){return '&Delta;Q = '+ (Q).toFixed(0)+' J'}], {fontSize:function(){return 22*board2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  //block
  //
  var j=0;
  var up =board2.create('image', ['/assets/plus.svg', [7.5-0.75, -2.25], [1.5,1.5]],{fixed:true});
  var down =board2.create('image', ['/assets/minuss.svg', [7.5+0.75, -2.25], [1.5,1.5]],{fixed:true});
  //var pause = board2.create('image', ['pause.svg', [-2, -6], [1.5,1.5]]);
  var run = function(){
  	if(Q<500){
  	Q+=100;}}
  var dec = function(){
  	if(Q >-500){
  	Q-=100;}}
  var mass = board2.create('glider', [3, 3, line], {name: '', size:0, face:'square', label:{offset:[-20, 0]}});
  //tape
  up.on('down', function(){i=1; run(); mass.moveTo([3, 3.0+R*Q.valueOf()/(Cv+R)/P],1000)});
  down.on('down', function(){i=-1;dec();mass.moveTo([3, 3.0+R*Q.valueOf()/(Cv+R)/P],1000)});
  board2.create('image', ['/assets/weight.svg', [1.1, function(){return mass.Y()-1;}], [0.75, 0.75]]);
  board2.create('image', ['/assets/weight.svg', [1.85, function(){return mass.Y()-1;}], [0.75,0.75]]);
  board2.create('image', ['/assets/weight.svg', [2.6, function(){return mass.Y()-1;}], [0.75,0.75]]);
  board2.create('image', ['/assets/weight.svg', [3.35, function(){return mass.Y()-1;}], [0.75,0.75]]);
  board2.create('image', ['/assets/weight.svg', [4.1, function(){return mass.Y()-1;}], [0.75,0.75]]);
  //pause.on('down', function(){i=0; Q=0;});
  var tape = board2.create('tapemeasure', [[-5.5, -4], [-5.5, function(){return mass.Y()-1.4}]], {name:'h_f', withLabel: true, label:{fontSize:16,cssStyle:'fontFamily:Oswald;'}, fixed:true, point1:{size:4,face:'square',color:'black'}, point2:{size:4,face:'square'}});
  board2.create('tapemeasure', [[-2.5, -4], [-2.5, 3.0+R*Q/(Cv+R)/P-1.4]], {name:'h_i', withLabel: true, ticks:false,label:{fontSize:16,cssStyle:'fontFamily:Oswald;'}, fixed:true, point1:{size:4,face:'square',color:'black'}, point2:{size:4,face:'square'}});
  //Piston
  var piston = board2.create('polygon',[[1, function(){return mass.Y()-1.4;}],[5,function(){return mass.Y()-1.4;}],[5,function(){return mass.Y()-0.95;}],[1, function(){return mass.Y()-0.95;}]], {fillColor:'black', withLines:false, fillOpacity:1, vertices:{visible:false}});
  //gas
  var gas=board2.create('polygon',[[1,-3.],[5,-3.],[5,function(){return mass.Y()-1.4;}],[1, function(){return mass.Y()-1.4;}]], {fillColor:'blue', withLines:false, fillOpacity:0.2, vertices:{visible:false}});
  board2.create('text', [6, 8, 'C_p = 7R/2'], {fontSize:function(){return 24*board2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});

  board2.create('text', [-5.0, 9.0, function(){return 'Total Heat to System &Delta;Q = '+ (Q).toFixed(2) + ' J'}], {fontSize:function(){return 18*board2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
  board2.create('text', [-5.0, 8.0, function(){return 'Work Done by the System &Delta;W = mg&Delta;h = '+ (m*g*R*Q.valueOf()/(Cv+R)/P).toFixed(2) + ' J'}], {fontSize:function(){return 18*board2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
  board2.create('text', [-5.0, 7.0, function(){return 'Change in Internal Energy &Delta;U = C_p&Delta;T = '+ (Cv*Q.valueOf()/(Cv+R)).toFixed(2) + ' J'}], {fontSize:function(){return 18*board2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', fixed:true});

  board2.create('image', ['/assets/ac.svg', [1, -5.25], [1,1]], {visible:function(){if(mass.Y()>3.0+R*Q.valueOf()/(Cv+R)/P){return true} else{return false}}});
  board2.create('image', ['/assets/ac.svg', [2.5, -5.25], [1,1]], {visible:function(){if(mass.Y()>3.0+R*Q.valueOf()/(Cv+R)/P){return true} else{return false}}});
  board2.create('image', ['/assets/ac.svg', [4., -5.25], [1,1]], {visible:function(){if(mass.Y()>3.0+R*Q.valueOf()/(Cv+R)/P){return true} else{return false}}});
  board2.create('image', ['/assets/flame.svg', [1., -5], [1,1]], {visible:function(){if(mass.Y()<3.0+R*Q.valueOf()/(Cv+R)/P){return true} else{return false}}});
  board2.create('image', ['/assets/flame.svg', [2.5, -5], [1,1]], {visible:function(){if(mass.Y()<3.0+R*Q.valueOf()/(Cv+R)/P){return true} else{return false}}});
  board2.create('image', ['/assets/flame.svg', [4., -5], [1,1]], {visible:function(){if(mass.Y()<3.0+R*Q.valueOf()/(Cv+R)/P){return true} else{return false}}});
  board2.unsuspendUpdate();
}
}
export default Boxes;
